import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Form, Button } from 'react-bootstrap'
import FormContainer from '../components/FormContainer'
import { saveShippingAddress } from '../actions/cartActions'
import CheckOutSteps from '../components/CheckOutSteps'

export class Shippingscreen extends Component {

    constructor(props) {
        super(props)
        this.state = {
            address: '',
            city: '',
            postalCode: '',
            country: '',
        } 
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleSubmit = (e) => {
        const {history} = this.props
        e.preventDefault()
        this.props.saveShippingAddress(this.state)
        history.push('/payment')
    }

    componentDidMount() {
        const {shippingAddress} = this.props.cart
        if(shippingAddress.length !== 0){
            this.setState(shippingAddress)
        }
    }

    render() {
        return (
            <FormContainer>
                <CheckOutSteps step1 step2/>
                <h1>Shipping</h1>
                <Form onSubmit={this.handleSubmit}>

                    <Form.Group controlId="formBasicAddress">
                        <Form.Label>Address</Form.Label>
                        <Form.Control type="text" placeholder="Enter Address" required
                        value={this.state.address} onChange={this.handleChange} name="address"/>
                    </Form.Group>

                    <Form.Group controlId="formBasicCity">
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" placeholder="Enter City" required
                        value={this.state.city} onChange={this.handleChange} name="city"/>
                    </Form.Group>

                    <Form.Group controlId="formBasicpostalCode">
                        <Form.Label>postal Code</Form.Label>
                        <Form.Control type="text" placeholder="Enter postal Code" required
                        value={this.state.postalCode} onChange={this.handleChange} name="postalCode"/>
                    </Form.Group>

                    <Form.Group controlId="formBasicCountry">
                        <Form.Label>Country</Form.Label>
                        <Form.Control type="text" placeholder="Enter Country" required
                        value={this.state.country} onChange={this.handleChange} name="country"/>
                    </Form.Group>

                    <Button type='submit' variant='outline-success' block>
                        Continue
                    </Button>

                </Form>
                
            </FormContainer>
        )
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveShippingAddress: saveShippingAddress,
    }, dispatch)
}

const mapStateToProps = (state) => {
    return ({
        cart: state.cart,
    })
}

export default connect(mapStateToProps, matchDispatchToProps)(Shippingscreen)

