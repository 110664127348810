import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { register } from '../actions/userActions'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import Loader from '../components/Loader'
import { MessageError } from '../components/MessageError'
import FormContainer from '../components/FormContainer'

export class RegisterScreen extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            name: '',
            email: '',
            password: '',
            passwordConfirm: '',
            message: '',
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if (this.state.password !== this.state.passwordConfirm){
            this.setState({message: 'Password do not Match'})
        }else {
            this.setState({message: ''})
            this.props.register(this.state.name, this.state.email, this.state.password)
        }
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    componentDidMount() {
        const {history, location} = this.props
        const redirect = location.search ? location.search.split('=')[1]: '/'
        const {userInfo} = this.props.userRegister
        if(userInfo){
            history.push(redirect)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {history, location} = this.props
        const redirect = location.search ? location.search.split('=')[1]: '/'
        const {userInfo} = this.props.userRegister
        if(prevProps.userRegister.userInfo !== userInfo){
            console.log('i was triggered and redirect is ', redirect)
            history.push(redirect)
        }
    }

    render() {
        const {loding, error} = this.props.userRegister
        const {message} = this.state
        return (
            <FormContainer>
                <Form onSubmit={this.handleSubmit}>
                <h1 className='text-center text-success'><i className="fas fa-user-lock"></i></h1>
                <h2 className='text-center'>Sign Up</h2>
                {error && <MessageError variant='danger'>{error}</MessageError> }
                {message && <MessageError variant='danger'>{message}</MessageError> }
                {loding && <Loader/>}

                <Form.Group controlId="formBasicName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="name" placeholder="Enter name" required
                     value={this.state.name} onChange={this.handleChange} name="name"/>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" required
                     value={this.state.email} onChange={this.handleChange} name="email"/>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter password" required
                    value={this.state.password} onChange={this.handleChange} name="password" />
                </Form.Group>

                <Form.Group controlId="formBasicPasswordConfirm">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control type="password" placeholder="Confirm password" required
                    value={this.state.passwordConfirm} onChange={this.handleChange} name="passwordConfirm" />
                </Form.Group>

                <Button type='submit' variant='outline-success' block>
                    {loding? 'Loading ...': 'Sign up'}
                </Button>

                </Form>

                <Row className='py-3'>
                    <Col>
                    <small>Already have an account please
                        <Link to='login/'> Login</Link>
                    </small>
                    </Col>
                </Row>
            </FormContainer>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        userRegister : state.userRegister,
    })
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        register: register,
    }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(RegisterScreen)