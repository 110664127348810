import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import MessageError from '../components/MessageError'
import FormContainer from '../components/FormContainer'
import { getUserDetails, updateUserByAdmin } from '../actions/userActions'
import { USER_ADMIN_UPDATE_RESET } from '../constants/userConstants'

function UserEditscreen({match, history}) {

    const {id} = match.params

    const dispatch = useDispatch()

    const userDetails = useSelector(state => state.userDetails)
    const {user, loading, error} = userDetails

    const userUpdate = useSelector(state => state.userUpdateByAdmin)
    const {loading: loadUpdate, error: errorUpdate, success} = userUpdate

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [isAdmin, setAdmin] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(updateUserByAdmin({_id:user._id, name, email, isAdmin}))
    }

    useEffect(() => {
        if(success) {
            dispatch({type: USER_ADMIN_UPDATE_RESET})
            history.push('/admin/users')
        }

        if(!user || user._id !== Number(id)){
            dispatch(getUserDetails(id))
        }else{
            setName(user.name)
            setEmail(user.email)
            setAdmin(user.isAdmin)
        }
    }, [dispatch, user, id, success, history])

    return (
        <div>
            <Link to='/admin/users'>Go Back</Link>
            {loading ?
            <Loader/>:
            error ?
            <MessageError variant="danger">{error}</MessageError>
            :
            <FormContainer>
                {errorUpdate && <MessageError variant="danger">{errorUpdate}</MessageError>}
                <Form onSubmit={handleSubmit}>
                    <h1>Edit User</h1>

                <Form.Group controlId="formBasicName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="name" placeholder="Enter name" required
                     value={name} onChange={(e) => setName(e.target.value)} name="name"/>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" required
                     value={email} onChange={(e) => setEmail(e.target.value)} name="email"/>
                </Form.Group>

                <Form.Group controlId="formBasicisAdmin">
                    
                    <Form.Check type="checkbox" label="Is Admin" 
                    checked={isAdmin} onChange={(e) => setAdmin(e.target.checked)}/>
                </Form.Group>

                <Button type='submit' variant='outline-success' block>
                    {loadUpdate? 'Loading ...': 'Update'}
                </Button>

                </Form>

            </FormContainer>
        }
        
        </div>
    )
}

export default UserEditscreen
