import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import Loader from '../components/Loader'
import { MessageError } from '../components/MessageError'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { login } from '../actions/userActions'
import FormContainer from '../components/FormContainer'

export class Loginscreen extends Component {

    constructor(props){
        super(props)
        this.state = {
            email: '',
            password: '',
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.props.login(this.state.email, this.state.password)
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    componentDidMount() {
        const {history, location} = this.props
        const redirect = location.search ? location.search.split('=')[1]: '/'
        const {userInfo} = this.props.userLogin
        if(userInfo){
            history.push(redirect)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {history, location} = this.props
        const redirect = location.search ? location.search.split('=')[1]: '/'
        const {userInfo} = this.props.userLogin
        if(prevProps.userLogin.userInfo !== userInfo){
            history.push(redirect)
        }
    }

    render() {
        const {location} = this.props
        const redirect = location.search ? location.search.split('=')[1]: null
        const {loding, error} = this.props.userLogin
        return (
            <FormContainer>
                <Form onSubmit={this.handleSubmit}>
                <h1 className='text-center text-success'><i className="fas fa-user-lock"></i></h1>
                <h2 className='text-center'>Sign In</h2>
                {error && <MessageError variant='danger'>{error}</MessageError> }
                {loding && <Loader/>}
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" required
                     value={this.state.email} onChange={this.handleChange} name="email"/>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter password" required
                    value={this.state.password} onChange={this.handleChange} name="password" />
                </Form.Group>

                <Button type='submit' variant='outline-success' block>
                    {loding? 'Loading ...': 'Sign in'}
                </Button>

                </Form>

                <Row className='py-3'>
                    <Col>
                    <small>Don't have an account please
                        <Link to={redirect ? `register?redirect=${redirect}`: 'register'}> Sign Up</Link>
                    </small>
                    </Col>
                </Row>
            </FormContainer>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        userLogin : state.userLogin,
    })
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        login: login,
    }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(Loginscreen)