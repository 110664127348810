import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Form, Button, Col } from 'react-bootstrap'
import FormContainer from '../components/FormContainer'
import { savePaymentMethod } from '../actions/cartActions'
import CheckOutSteps from '../components/CheckOutSteps'

export class Paymentscreen extends Component {

    constructor(props) {
        super(props)
        this.state = {
            paymentMethod : 'PayPal'
        }
    }

    componentDidMount() {
        const {history} = this.props
        const {shippingAddress} = this.props.cart
        if (shippingAddress.length === 0) {
           history.push('/shipping')
        }
    }

    handleSubmit = (e) => {
        const {history} = this.props
        e.preventDefault()
        this.props.savePaymentMethod(this.state.paymentMethod)
        history.push('/placeorder')

    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }


    render() {
        return (
            <FormContainer>
                <CheckOutSteps step1 step2 step3/>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group>
                        <Form.Label as='legend'>Select Method</Form.Label>
                        <Col>
                            <Form.Check type='radio'
                                        label='paypal or Credit Card'
                                        id='paypal'
                                        name='paymentMethod'
                                        checked
                                        value='Paypal'
                                        onClick={(e) => console.log(e.target.value)}
                                        onChange={this.handleChange}/>
                            
                            <Form.Check type='radio'
                                        label='Paysera'
                                        id='Paysera'
                                        name='paymentMethod'
                                        value='Paysera'
                                        onClick={(e) => console.log(this.state.paymentMethod)}
                                        onChange={this.handleChange}/>
                            <Form.Check type='radio'
                                        label='E-Bank'
                                        id='E-Bank'
                                        name='paymentMethod'
                                        value='E-Bank'
                                        onClick={(e) => console.log(this.state.paymentMethod)}
                                        onChange={this.handleChange}/>
                                        
                        </Col>
                    </Form.Group>
                    <Button type='submit' variant='outline-success' block>Continue</Button>
                </Form>

                
            </FormContainer>
        )
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        savePaymentMethod: savePaymentMethod,
    }, dispatch)
}

const mapStateToProps = (state) => {
    return ({
        cart: state.cart,
    })
}

export default connect(mapStateToProps, matchDispatchToProps)(Paymentscreen)