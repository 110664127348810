import React, { Component } from 'react'
import { Card } from 'react-bootstrap'
import Rating from './Rating'
import { Link } from 'react-router-dom'

export class Product extends Component {
    render() {
        const {product} = this.props
        return (
            <Card className="my-3 p-3 rounded">
                <Link to={`/product/${product._id}`}>
                    <Card.Img src={product.image} alt="picture" />
                    <Card.Body>
                        <Card.Title as="div">
                            <strong>{product.name}</strong>
                        </Card.Title>
                        <Card.Text as="div">
                            <div className="my-3">
                                <Rating value={product.rating} text={`${product.numReviews} reviews`} color='#f8e825'/>
                            </div>
                        </Card.Text>
                        <Card.Text as="h3">
                            <div className="text-center">
                                ${product.price}
                            </div>
                            
                        </Card.Text>

                    </Card.Body>
                </Link>
            </Card>
        )
    }
}

export default Product
