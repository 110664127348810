import {PRODUCT_LIST_REQUEST,
        PRODUCT_LIST_SUCCESS,
        PRODUCT_LIST_FAIL,

        PRODUCT_DETAILS_REQUEST,
        PRODUCT_DETAILS_SUCCESS,
        PRODUCT_DETAILS_FAIL,

        PRODUCT_DELETE_REQUEST,
        PRODUCT_DELETE_SUCCESS,
        PRODUCT_DELETE_FAIL,

        PRODUCT_CREATE_REQUEST,
        PRODUCT_CREATE_SUCCESS,
        PRODUCT_CREATE_FAIL,

        PRODUCT_UPDATE_REQUEST,
        PRODUCT_UPDATE_SUCCESS,
        PRODUCT_UPDATE_FAIL,

        PRODUCT_CREATE_REVIEW_REQUEST,
        PRODUCT_CREATE_REVIEW_SUCCESS,
        PRODUCT_CREATE_REVIEW_FAIL,
        PRODUCT_CREATE_REVIEW_RESET,

        PRODUCT_TOP_REQUEST,
        PRODUCT_TOP_SUCCESS,
        PRODUCT_TOP_FAIL,

        } from '../constants/productConstants'

import axiosInstance from '../axiosApi'

export const listProducs = (query = '') => async (dispatch) => {
    try {
        dispatch({type : PRODUCT_LIST_REQUEST})
        const {data} = await axiosInstance.get(`products/${query}`)
        dispatch({
            type : PRODUCT_LIST_SUCCESS,
            payload : data
        })
    } catch (error) {
        dispatch({
            type : PRODUCT_LIST_FAIL,
            payload : error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
        })
    }
}



export const detailsProduct = (id) => async (dispatch) => {
    try {
        dispatch({type : PRODUCT_DETAILS_REQUEST})
        const {data} = await axiosInstance.get(`product/${id}`)
        dispatch({
            type : PRODUCT_DETAILS_SUCCESS,
            payload : data
        })
    } catch (error) {
        dispatch({
            type : PRODUCT_DETAILS_FAIL,
            payload : error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
        })
    }
}

export const deleteProduct = (id) => async (dispatch) => {
    try {
        dispatch({type : PRODUCT_DELETE_REQUEST})
        const {data} = await axiosInstance.delete(`product/${id}`)
        dispatch({
            type : PRODUCT_DELETE_SUCCESS,
            payload : data
        })
    } catch (error) {
        dispatch({
            type : PRODUCT_DELETE_FAIL,
            payload : error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
        })
    }
}

export const createProduct = () => async (dispatch) => {
    try {
        dispatch({type : PRODUCT_CREATE_REQUEST})
        const {data} = await axiosInstance.post(`products/`, {})
        dispatch({
            type : PRODUCT_CREATE_SUCCESS,
            payload : data
        })
    } catch (error) {
        dispatch({
            type : PRODUCT_CREATE_FAIL,
            payload : error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
        })
    }
}

export const updateProduct = (product) => async (dispatch) => {
    try {
        dispatch({type : PRODUCT_UPDATE_REQUEST})

        const {data} = await axiosInstance.put(`product/${product._id}`, product)
        dispatch({
            type : PRODUCT_UPDATE_SUCCESS,
        })

        dispatch({
            type : PRODUCT_DETAILS_SUCCESS,
            payload : data
        })

    } catch (error) {
        console.log(error.response)
        dispatch({
            type : PRODUCT_UPDATE_FAIL,
            payload : error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
        })
    }
}

export const createProductReview = (productId, review) => async (dispatch) => {
    try {
        dispatch({type : PRODUCT_CREATE_REVIEW_REQUEST})

        const {data} = await axiosInstance.post(`product/${productId}/review/`, review)
        dispatch({
            type : PRODUCT_CREATE_REVIEW_SUCCESS,
        })

        dispatch({
            type : PRODUCT_DETAILS_SUCCESS,
            payload : data
        })

        dispatch({type: PRODUCT_CREATE_REVIEW_RESET})

    } catch (error) {
        dispatch({
            type : PRODUCT_CREATE_REVIEW_FAIL,
            payload : error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
        })
    }
}

export const listTopProducs = () => async (dispatch) => {
    try {
        dispatch({type : PRODUCT_TOP_REQUEST})
        const {data} = await axiosInstance.get(`products/top/`)
        dispatch({
            type : PRODUCT_TOP_SUCCESS,
            payload : data
        })
    } catch (error) {
        dispatch({
            type : PRODUCT_TOP_FAIL,
            payload : error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
        })
    }
}