import React, {useEffect} from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import { MessageError } from '../components/MessageError'
import { listProducs, deleteProduct, createProduct } from '../actions/productActions'
import { PRODUCT_CREATE_RESET } from '../constants/productConstants'
import Paginate from '../components/Paginate'

function ProdcutListscreen({history, location}) {
    const dispatch = useDispatch()

    const productList = useSelector(state => state.productList)
    const {loding, error, products, page, pages} = productList

    const productDelete = useSelector(state => state.productDelete)
    const {loading: loadDelete, error: errorDelete, success: successDelete} = productDelete

    const productCreate = useSelector(state => state.productCreate)
    const {loading: loadCreate, error: errorCreate,success: successCreate, product: createdProduct} = productCreate


    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin


    useEffect(() => {
        dispatch({type: PRODUCT_CREATE_RESET})
        if(!userInfo && !userInfo.isAdmin){
            history.push('/login')
        }
        if(successCreate){
            history.push(`/admin/product/${createdProduct._id}`)
        }else{
            dispatch(listProducs(location.search))
        }
        
    }, [dispatch, history, userInfo, successDelete, successCreate, createdProduct, location])

    const handleDelete = (id) => {
        if(window.confirm('Are you sure to delete this Product ?')){
            dispatch(deleteProduct(id))
        }  
    }

    const createProductHandler = () => {
        dispatch(createProduct())
    }

    return (
        <div>
            <Row className="align-items-center">
                <Col>
                    <h1>Products</h1>
                </Col>
                <Col className="text-right">
                    <Button className="my-3" onClick={createProductHandler}>
                        <i className="fas fa-plus"></i> Add product
                    </Button>
                </Col>

            </Row>
            
            {loadDelete && <Loader/>}
            {errorDelete && <MessageError variant="danger">{errorDelete}</MessageError>}

            {loadCreate && <Loader/>}
            {errorCreate && <MessageError variant="danger">{errorCreate}</MessageError>}


            {loding?
            <Loader/>
            :
            error ? <MessageError variant='danger'>{error}</MessageError>
            :
            <div>
            <Table striped bordered hover responsive className="table-sm">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Category</th>
                        <th>Brand</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {products.map(product => (
                        <tr key={product._id}>
                            <td>{product._id}</td>
                            <td>{product.name}</td>
                            <td>${product.price}</td>
                            <td>{product.category}</td>
                            <td>{product.brand}</td>
                            <td>
                                <LinkContainer to={`/admin/product/${product._id}`}>
                                    <Button size="sm" variant="dark">
                                    <i className="fas fa-edit"></i>
                                    </Button>
                                </LinkContainer>
                                <Button size="sm" className="ml-2" variant="outline-success" onClick={() => handleDelete(product._id)}>
                                    <i className="fas fa-trash"></i>
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Paginate page={page} pages={pages} isAdmin={true} />
            </div>
            }
        </div>
    )
}

export default ProdcutListscreen
