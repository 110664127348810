import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import Product from '../components/Product.js'
import { listProducs } from '../actions/productActions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Loader from '../components/Loader'
import { MessageError } from '../components/MessageError'
import Paginate from '../components/Paginate'
import ProductCaroussel from '../components/ProductCarossel'


export class Homescreen extends Component {

    componentDidMount() {
        const query = this.props.location.search
        this.props.listProducs(query)
    }

    componentDidUpdate(prevProps) {
        const query = this.props.location.search
        if(prevProps.location.search !== query){
            this.props.listProducs(query)
        }
    }
    
    render() {
        const query = this.props.location.search
        const {products, error, loding, page, pages} = this.props.productList
        return (
            <div>
                {!query && <ProductCaroussel />}
                
                <h1>Lastest Products</h1>
                {loding ? <Loader />
                 : error ? <MessageError children={error} variant='success' />
                    :
                        <div>
                            <Row>
                            {products.map(product => (
                                <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                                    <Product product={product} />
                                </Col>
                            ))}
                            </Row>
                            <Paginate page={page} pages={pages} querySearch={query} />
                        </div>
                       
                        
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        productList : state.productList,
    })
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        listProducs: listProducs,
    }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(Homescreen)
