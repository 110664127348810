import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap'
import Rating from '../components/Rating'
import { detailsProduct, createProductReview } from '../actions/productActions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Loader from '../components/Loader'
import { MessageError } from '../components/MessageError'

export class Productscreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            qty: 1,
            rating: 0,
            comment: '',
        }
    }

    handleQtyChange = (e) => {
        this.setState({
            qty: parseInt(e.target.value)
        })
    }

    addToCart = () => {
        const {id} =this.props.match.params
        console.log("Item added to cart ", this.props.match.params.id)
        this.props.history.push(`/cart/${id}?qty=${this.state.qty}`)
    }

    componentDidMount() {
        const { id } = this.props.match.params
        this.props.detailsProduct(id)
    }
    
    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const {id} =this.props.match.params
        const {rating, comment} = this.state
        this.props.createProductReview(id, {
            rating,
            comment
        })
        this.setState({
            rating: 0,
            comment: ''
        })
    }

    render() {
        const {userInfo} = this.props.userLogin
        const {product, loding, error} = this.props.productDetails
        const {loading: loadReview, error: errorReview} = this.props.productReviewCreate
        const {qty} = this.state

        return (
            <div>
                <Link to="/" className="btn btn-light">Go Back</Link>
                {loding ?
                <Loader/>:
                error?
                <MessageError variant="danger">{error}</MessageError>
                :
                <div>
                <Row>
                    <Col md={6}>
                        <Card>
                        <Image src={product.image} alt={product.name} fluid/>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <h3>{product.name}</h3>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <Rating value={product.rating} text={`${product.numReviews} reviews`} color='#f8e825'/>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                Description: {product.description}
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                    
                    <Col md={3}>
                        <Card>
                        <ListGroup variant="flush">
                        <ListGroup.Item>
                            <Row>
                                <Col>Price:</Col>
                                <Col><strong>${product.price}</strong></Col>
                            </Row>
                        </ListGroup.Item>
                        </ListGroup>

                        <ListGroup variant="flush">
                        <ListGroup.Item>
                            <Row>
                                <Col>Status:</Col>
                                <Col>{product.countInStock > 0 ? 'In Stock': 'Out of Stock'}</Col>
                            </Row>
                        </ListGroup.Item>

                        {product.countInStock > 0 &&(
                            <ListGroup.Item>
                            <Row>
                                <Col>QTY:</Col>
                                <Col>
                                    <Form.Control as="select"
                                     value={qty}
                                     onChange={this.handleQtyChange}>
                                        {
                                         [...Array(product.countInStock).keys()].map((val) => (
                                             <option key={val + 1} value={val + 1}>
                                                 {val + 1}
                                             </option>
                                         ))
                                        }
                                    </Form.Control>
                                </Col>
                            </Row>
                            </ListGroup.Item>
                        )}

                        <ListGroup.Item>
                            <Button className="btn btn-block" disabled={product.countInStock === 0}
                             type="button" onClick={this.addToCart}>Add To Cart</Button>
                        </ListGroup.Item>
                        </ListGroup>
                        
                        </Card>
                    </Col>                
                    
                    
                </Row>
                <Row className='my-2'>
                    <Col md={6}>
                        <h4>Reviews</h4>
                        {product.reviews.length === 0 ?
                         <div>
                            <MessageError variant="info">No reviews</MessageError>
                            <ListGroup variant="flush">
                            <ListGroup.Item>
                                <h4>Write a Review</h4>
                                {loadReview && <Loader/>}
                                {errorReview && <MessageError variant='danger'>{errorReview}</MessageError>}
                                {userInfo ?
                                <Form onSubmit={this.handleSubmit}>
                                    
                                    <Form.Group controlId='rating'>
                                        <Form.Label>Rating</Form.Label>
                                        <Form.Control
                                        as="select"
                                        value={this.state.rating}
                                        onChange={this.handleChange}
                                        name="rating"
                                        >
                                            <option value=''>Select ...</option>
                                            <option value='1'>1 - Poor</option>
                                            <option value='2'>2 - Fair</option>
                                            <option value='3'>3 - Good</option>
                                            <option value='4'>4 - Very good</option>
                                            <option value='5'>5 - Excellent</option>
                                    </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId='comment'>
                                        <Form.Label>Review</Form.Label>
                                        <Form.Control
                                        as='textarea'
                                        row='5'
                                        value={this.state.comment}
                                        placeholder='Write a review ...'
                                        name="comment"
                                        onChange={this.handleChange}
                                        >
                                        </Form.Control>
                                    </Form.Group>

                                    <Button disabled={loadReview} type='submit' variant='outline-info'>
                                        Submit
                                    </Button>

                                </Form>
                                :<MessageError variant='info'>

                                    You must <Link to='/login'>Login</Link> to write a review
                                </MessageError>
                                }
                            </ListGroup.Item>
                            </ListGroup>
                         </div>
                         
                        : 
                        <ListGroup variant="flush">
                            {product.reviews.map((review) => (
                                <ListGroup.Item key={review._id}>
                                    <strong>{review.name}</strong>
                                    <Rating value={review.rating} color='#f8e825'/>
                                    <p>{new Date(review.created_at).toDateString()}</p>
                                    <p>{review.comment}</p>
                                </ListGroup.Item>
                            ))}
                            <ListGroup.Item>
                                <h4>Write a Review</h4>
                                {loadReview && <Loader/>}
                                {errorReview && <MessageError variant='danger'>{errorReview}</MessageError>}
                                {userInfo ?
                                <Form onSubmit={this.handleSubmit}>
                                    
                                    <Form.Group controlId='rating'>
                                        <Form.Label>Rating</Form.Label>
                                        <Form.Control
                                        as="select"
                                        value={this.state.rating}
                                        onChange={this.handleChange}
                                        name="rating"
                                        >
                                            <option value=''>Select ...</option>
                                            <option value='1'>1 - Poor</option>
                                            <option value='2'>2 - Fair</option>
                                            <option value='3'>3 - Good</option>
                                            <option value='4'>4 - Very good</option>
                                            <option value='5'>5 - Excellent</option>
                                    </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId='comment'>
                                        <Form.Label>Review</Form.Label>
                                        <Form.Control
                                        as='textarea'
                                        row='5'
                                        value={this.state.comment}
                                        placeholder='Write a review ...'
                                        name="comment"
                                        onChange={this.handleChange}
                                        >
                                        </Form.Control>
                                    </Form.Group>

                                    <Button disabled={loadReview} type='submit' variant='outline-info'>
                                        Submit
                                    </Button>

                                </Form>
                                :<MessageError variant='info'>

                                    You must <Link to='/login'>Login</Link> to write a review
                                </MessageError>
                                }
                            </ListGroup.Item>
                        </ListGroup>
                        }
                    </Col>
                </Row>
                </div>
                }
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return ({
        productDetails : state.productDetails,
        userLogin : state.userLogin,
        productReviewCreate : state.productReviewCreate,
    })
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        detailsProduct: detailsProduct,
        createProductReview: createProductReview,
    }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(Productscreen)