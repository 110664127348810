import { 
    ORDER_CREATE_REQUEST,
    ORDER_CREATE_SUCCESS,
    ORDER_CREATE_FAIL,
    ORDER_CREATE_RESET,

    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,

    ORDER_PAY_REQUEST,
    ORDER_PAY_SUCCESS,
    ORDER_PAY_FAIL,
    ORDER_PAY_RESET,

    ORDER_CUSTOMER_REQUEST,
    ORDER_CUSTOMER_SUCCESS,
    ORDER_CUSTOMER_FAIL,
    
    ORDER_LIST_REQUEST,
    ORDER_LIST_SUCCESS,
    ORDER_LIST_FAIL,

    ORDER_DELIVER_REQUEST,
    ORDER_DELIVER_SUCCESS,
    ORDER_DELIVER_FAIL,
    ORDER_DELIVER_RESET,
} from '../constants/OrderConstants'
import { CART_CLEAR_ITEM } from '../constants/cartConstants'
import axiosInstance from '../axiosApi'

export const createOrder = (order) => async (dispatch) => {
    try {
        dispatch({type: ORDER_CREATE_REQUEST})
        const {data} = await axiosInstance.post('order/add', order)

        dispatch({
            type: ORDER_CREATE_SUCCESS,
            payload: data
        })

        dispatch({
            type: ORDER_CREATE_RESET
        })

        dispatch({
            type: CART_CLEAR_ITEM
        })
        localStorage.removeItem('cartItems')

    } catch (error) {
        dispatch({
            type : ORDER_CREATE_FAIL,
            payload : error.response && error.response.data
            ? error.response.data
            : error.message,
        })
    }
}

export const getOrderDetails = (id) => async (dispatch) => {
    try {
        dispatch({type: ORDER_DETAILS_REQUEST})
        const {data} = await axiosInstance.get(`order/${id}`)

        dispatch({
            type: ORDER_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type : ORDER_DETAILS_FAIL,
            payload : error.response && error.response.data
            ? error.response.data.detail
            : error.message,
        })
    }
}


export const payOrder = (id) => async (dispatch) => {
    try {
        dispatch({type: ORDER_PAY_REQUEST})
        const {data} = await axiosInstance.put(`order/${id}`)

        dispatch({
            type: ORDER_PAY_SUCCESS,
            payload: data
        })

        dispatch({
            type: ORDER_DETAILS_SUCCESS,
            payload: data
        })

        dispatch({type: ORDER_PAY_RESET})


    } catch (error) {
        dispatch({
            type : ORDER_PAY_FAIL,
            payload : error.response && error.response.data
            ? error.response.data.detail
            : error.message,
        })
    }
}

export const deliverOrder = (id) => async (dispatch) => {
    try {
        dispatch({type: ORDER_DELIVER_REQUEST})
        const {data} = await axiosInstance.put(`order/${id}/deliver/`, {})

        dispatch({
            type: ORDER_DELIVER_SUCCESS,
            payload: data
        })

        dispatch({
            type: ORDER_DETAILS_SUCCESS,
            payload: data
        })

        dispatch({type: ORDER_DELIVER_RESET})


    } catch (error) {
        dispatch({
            type : ORDER_DELIVER_FAIL,
            payload : error.response && error.response.data
            ? error.response.data.detail
            : error.message,
        })
    }
}


export const getCustomerOrder = () => async (dispatch) => {
    try {
        dispatch({type: ORDER_CUSTOMER_REQUEST})
        const {data} = await axiosInstance.get(`user/order`)

        dispatch({
            type: ORDER_CUSTOMER_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type : ORDER_CUSTOMER_FAIL,
            payload : error.response && error.response.data
            ? error.response.data.detail
            : error.message,
        })
    }
}

export const getListOrders = () => async (dispatch) => {
    try {
        dispatch({type: ORDER_LIST_REQUEST})
        const {data} = await axiosInstance.get(`orders/`)

        dispatch({
            type: ORDER_LIST_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type : ORDER_LIST_FAIL,
            payload : error.response && error.response.data
            ? error.response.data.detail
            : error.message,
        })
    }
}
