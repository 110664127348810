import React, {useState} from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Form } from 'react-bootstrap'

function SearchBox() {
    
    const [keyword, setKeyword] = useState('')
    const history = useHistory()

    const handleSubmit = (e) => {
        e.preventDefault()
        if(keyword){
            history.push(`/?q=${keyword}&page=1`)
        }
    }

    return (
        <Form onSubmit={handleSubmit} inline>
            <Form.Control 
            type='text'
            name='q' 
            onChange={(e) => setKeyword(e.target.value)}
            className='mr-sm-2'
            placeholder="Search"
            />
            <Button 
            type='submit'
            variant='outline-info'
            disabled={!keyword}
            >Search</Button>
        </Form>
    )
}

export default SearchBox
