import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import MessageError from '../components/MessageError'
import FormContainer from '../components/FormContainer'
import { detailsProduct, updateProduct } from '../actions/productActions'
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants'
import axiosInstance from '../axiosApi'

function ProductEditscreen({match, history}) {

    const {id} = match.params

    const dispatch = useDispatch()

    const productDetails = useSelector(state => state.productDetails)
    const {product, loding, error} = productDetails

    const productUpdate = useSelector(state => state.productUpdate)
    const {success, loading: loadUpdate, error: errorUpdate} = productUpdate

    const [name, setName] = useState('')
    const [price, setPrice] = useState(0.00)
    const [image, setImage] = useState('')
    const [brand, setBrand] = useState('')
    const [category, setCategory] = useState('')
    const [countInStock, setCountInStock] = useState(0.00)
    const [description, setDescription] = useState('')
    const [uploading, setUploading] = useState(false)
    

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0]
        const fd = new FormData()
        fd.append('image', file)
        fd.append('product_id', id)
        setUploading(true)
        try {
            const {data} = await axiosInstance.post('product/upload/', fd)
            setImage(data)
            setUploading(false)
        } catch (error) {
            setUploading(false)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // const formId = document.getElementById('update_product')
        // let fd = new FormData(formId)
        dispatch(updateProduct({
            _id: product._id,
            name,
            brand,
            price,
            category,
            countInStock,
            description
        }))
    }

    useEffect(() => {
        if(success){
            dispatch({type: PRODUCT_UPDATE_RESET})
            history.push('/admin/products')
        }else{
            if(!product || product._id !== Number(id)){
                dispatch(detailsProduct(id))
            }else{
                setName(product.name)
                setPrice(product.price)
                setImage(product.image)
                setBrand(product.brand)
                setCategory(product.category)
                setCountInStock(product.countInStock)
                setDescription(product.description)
    
    
            }
        }
        
    }, [dispatch, product, id, history, success])

    return (
        <div>
            <Link to='/admin/products'>Go Back</Link>
            {loding ?
            <Loader/>:
            error ?
            <MessageError variant="danger">{error}</MessageError>
            :
            <FormContainer>
                
                <Form id="update_product" onSubmit={handleSubmit}>
                    <h1>Edit Product</h1>
                    {loadUpdate && <Loader/>}
                    {errorUpdate && <MessageError variant="danger">{errorUpdate}</MessageError>}

                <Form.Group controlId="formBasicName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="name" placeholder="Enter name" required
                     value={name} onChange={(e) => setName(e.target.value)} name="name"/>
                </Form.Group>

                <Form.Group controlId="formBasicPrice">
                    <Form.Label>Price</Form.Label>
                    <Form.Control type="number" placeholder="Enter price" required
                     value={price} onChange={(e) => setPrice(e.target.value)} name="price"/>
                </Form.Group>

                <Form.Group controlId="formBasicImage">
                    <Form.Label>Image</Form.Label>
                    <Form.Control type="text" placeholder="Enter image" required
                     value={image} onChange={(e) => setImage(e.target.value)} name="image"/>
                    <Form.File
                    id="imageFIle"
                    label="Choose an Image"
                    custom
                    onChange={uploadFileHandler}>
                    </Form.File>
                    {uploading && <Loader/>}
                </Form.Group>

                <Form.Group controlId="formBasicBrand">
                    <Form.Label>Brand</Form.Label>
                    <Form.Control type="text" placeholder="Enter brand" required
                     value={brand} onChange={(e) => setBrand(e.target.value)} name="brand"/>
                </Form.Group>

                <Form.Group controlId="formBasicStock">
                    <Form.Label>Stock</Form.Label>
                    <Form.Control type="number" placeholder="Enter Stock" required
                     value={countInStock} onChange={(e) => setCountInStock(e.target.value)} name="countInStock"/>
                </Form.Group>

                <Form.Group controlId="formBasicCategory">
                    <Form.Label>Category</Form.Label>
                    <Form.Control type="text" placeholder="Enter Category" required
                     value={category} onChange={(e) => setCategory(e.target.value)} name="category"/>
                </Form.Group>

                <Form.Group controlId="formBasicDescription">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as='textarea'
                                  row='5'
                                  placeholder="Enter Description" required
                     value={description} onChange={(e) => setDescription(e.target.value)} name="description"/>
                </Form.Group>

                <Button type='submit' variant='outline-success' block>
                    Update
                </Button>
                
                </Form>

            </FormContainer>
        }
        
        </div>
    )
}

export default ProductEditscreen
