import React, { Component } from 'react'
import { Container, Col, Row } from 'react-bootstrap'

export class Footer extends Component {
    render() {
        return (
            <footer>
                <Container>
                    <Row>
                        <Col className="text-center py-3">Copyright &copy; OuedKniss</Col>
                    </Row>
                </Container>
            </footer>
        )
    }
}

export default Footer
