import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateUserProfile } from '../actions/userActions'
import { LinkContainer } from 'react-router-bootstrap'
import { Form, Button, Row, Col, Table } from 'react-bootstrap'
import Loader from '../components/Loader'
import { MessageError } from '../components/MessageError'
import { getCustomerOrder } from '../actions/orderActions'
//import FormContainer from '../components/FormContainer'

export class Profilescreen extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            oldPassword: '',
            password: '',
            passwordConfirm: '',
            message: '',
        }
    }

    componentDidMount() {
        const {history} = this.props
        const {userInfo} = this.props.userLogin
        if (!userInfo){
            history.push('/login')
        }else{
            this.setState({
                name: userInfo.name,
                email: userInfo.email
            })
            this.props.getCustomerOrder()
        }
    }

    componentDidUpdate(prevProps) {
        const {history, userLogin} = this.props
        if(userLogin.userInfo === null){
            history.push('/login')
        }
    }

    handleSubmit = (e) => {
        const {name, email, password, oldPassword} = this.state
        e.preventDefault()
        this.setState({message: ''})
        if (this.state.password !== this.state.passwordConfirm){
            this.setState({message: 'Password do not Match'})
        }else{
            this.props.updateUserProfile(name, email, oldPassword, password)
        }
        
    }
    
    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    render() {
        const {loding, error} = this.props.userUpdate
        const {orders, loading: loadOrder, error: errorOrder} = this.props.orderCustomer
        const {message} = this.state
        return (
            <Row>
                <Col md={3}>
                    <h2>User Profile</h2>
                    <Form onSubmit={this.handleSubmit}>
                        {error && <MessageError variant='danger'>{error}</MessageError> }
                        {message && <MessageError variant='danger'>{message}</MessageError> }
                        {loding && <Loader/>}

                        <Form.Group controlId="formBasicName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="name" placeholder="Enter name" required
                            value={this.state.name} onChange={this.handleChange} name="name"/>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" required
                            value={this.state.email} onChange={this.handleChange} name="email"/>
                        </Form.Group>

                        <Form.Group controlId="formBasicOldPassword">
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control type="password" placeholder="Current password" 
                            value={this.state.oldPassword} onChange={this.handleChange} name="oldPassword" />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter New password" 
                            value={this.state.password} onChange={this.handleChange} name="password" />
                        </Form.Group>

                        <Form.Group controlId="formBasicPasswordConfirm">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" placeholder="Confirm password" 
                            value={this.state.passwordConfirm} onChange={this.handleChange} name="passwordConfirm" />
                        </Form.Group>

                        <Button type='submit' variant='outline-success' block>
                            {loding? 'Loading ...': 'Update'}
                        </Button>

                        </Form>

                        </Col>
                <Col md={9}>
                            <h2>My Orders</h2>
                            {loadOrder?
                            <Loader/>
                            :
                            errorOrder?
                            <MessageError variant='danger'>{errorOrder}</MessageError>
                            :
                            <Table striped responsive className="table-sm">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Date</th>
                                        <th>Total</th>
                                        <th>Paid</th>
                                        <th>Delivered</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders.map(order => (
                                        <tr key={order._id}>
                                            <td>{order._id}</td>
                                            <td>{new Date(order.createdAt).toDateString()}</td>
                                            <td>${order.totalPrice}</td>
                                            <td>{!order.isPaid ?
                                             <i className="fas fa-times" style={{color: 'red'}}></i>:
                                             new Date(order.paidAt).toDateString()}</td>
                                            <td>{!order.isDelivered ?
                                             <i className="fas fa-times" style={{color: 'red'}}></i>:
                                             new Date(order.deliveredAt).toDateString()}</td>
                                             <td><LinkContainer to={`/order/${order._id}`}>
                                                 <Button size="sm" variant="dark">Details</Button>
                                                </LinkContainer></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            }
                        </Col>
            </Row>
        )
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateUserProfile: updateUserProfile,
        getCustomerOrder: getCustomerOrder,
    }, dispatch)
}

const mapStateToProps = (state) => {
    return ({
        userLogin : state.userLogin,
        userUpdate : state.userUpdate,
        orderCustomer : state.orderCustomer,
    })
}

export default connect(mapStateToProps, matchDispatchToProps)(Profilescreen)