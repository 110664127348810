import React from 'react'
import { Pagination } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

function Paginate({page, pages, querySearch='', isAdmin=false}) {
    if (querySearch){
        querySearch = querySearch.split('?q=')[1].split('&')[0]
    }
    return (pages > 1 &&(
        <Pagination>
            {[...Array(pages).keys()].map(x => (
                <LinkContainer key={x+1}

                 to={!isAdmin ?
                    `/?q=${querySearch}&page=${x + 1}`
                    :
                    `/admin/products/?q=${querySearch}&page=${x + 1}`
                }
                 >
                    <Pagination.Item active={x+1 === page}>{x + 1}</Pagination.Item>
                </LinkContainer>
            ))}
        </Pagination>
    )  
    )
}

export default Paginate
