import React, {useEffect} from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import { MessageError } from '../components/MessageError'
import { getListUsers, deleteUser } from '../actions/userActions'

function UserListscreen({history}) {
    const dispatch = useDispatch()
    const userList = useSelector(state => state.userList)
    const {loading, error, users} = userList

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const userDelete = useSelector(state => state.userDelete)
    const {loading: loadDelete, success: successDelete} = userDelete

    useEffect(() => {
        if(userInfo && userInfo.isAdmin){
            dispatch(getListUsers())
        }else{
            history.push('/login')
        }
        
    }, [dispatch, history, userInfo, successDelete])

    const handleDelete = (id) => {
        if(window.confirm('Are you sure to delete this User ?')){
            dispatch(deleteUser(id))
        }  
    }

    return (
        <div>
            <h1>Users</h1>
            {loading?
            <Loader/>
            :
            error ? <MessageError variant='danger'>{error}</MessageError>
            :
            <Table striped bordered hover responsive className="table-sm">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Admin</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {users.map(user => (
                        <tr key={user._id}>
                            <td>{user._id}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.isAdmin ? 
                                <i className="fas fa-check" style={{color: "green"}}></i>
                                :
                                <i className="fas fa-times" style={{color: "red"}}></i>
                            }</td>
                            <td>
                                <LinkContainer to={`/admin/user/${user._id}`}>
                                    <Button size="sm" variant="dark">
                                    <i className="fas fa-edit"></i>
                                    </Button>
                                </LinkContainer>
                                <Button size="sm" className="ml-2" variant="outline-success" onClick={() => handleDelete(user._id)}>
                                    {loadDelete ?<i className="fas fa-circle-notch"></i>
                                    :<i className="fas fa-trash"></i>}
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            }
        </div>
    )
}

export default UserListscreen
