import React, { Component } from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import Homescreen from './screens/Homescreen'
import Productscreen from './screens/Productscreen'
import Cartscreen from './screens/Cartscreen'
import { Container } from 'react-bootstrap'
import {HashRouter as Router, Route, Switch} from 'react-router-dom'
import Loginscreen from './screens/Loginscreen'
import RegisterScreen from './screens/RegisterScreen'
import Profilescreen from './screens/Profilescreen'
import Shippingscreen from './screens/Shippingscreen'
import Paymentscreen from './screens/Paymentscreen'
import PlaceOrderscreen from './screens/PlaceOrderscreen'
import Orderscreen from './screens/Orderscreen'
import UserListscreen from './screens/UserListscreen'
import UserEditscreen from './screens/UserEditscreen'
import ProdcutListscreen from './screens/ProductListscreen'
import ProductEditscreen from './screens/ProductEditscreen'
import OrderListscreen from './screens/OrderListscreen'

export class App extends Component {
  render() {
    return (
      <Router>
        <Header />
        <main className="py-3">
          <Container>
            
              <Switch>
                <Route path="/" exact component={Homescreen} />
                <Route path="/product/:id" exact component={Productscreen} />
                <Route path="/cart/:id?" component={Cartscreen} />
                <Route path="/login" component={Loginscreen} />
                <Route path="/register" component={RegisterScreen} />
                <Route path="/profile" component={Profilescreen} />
                <Route path="/shipping" component={Shippingscreen} />
                <Route path="/payment" component={Paymentscreen} />
                <Route path="/placeorder" component={PlaceOrderscreen} />
                <Route path="/order/:id" component={Orderscreen} />
                <Route path="/admin/users" component={UserListscreen} />
                <Route path="/admin/user/:id" component={UserEditscreen} />
                <Route path="/admin/products" component={ProdcutListscreen} />
                <Route path="/admin/product/:id" component={ProductEditscreen} />
                <Route path="/admin/orders" component={OrderListscreen} />
              </Switch>
            
            
          </Container>
        </main>
        <Footer />
      </Router>
    )
  }
}

export default App
