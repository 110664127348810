import React, { Component } from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logout } from '../actions/userActions'
import SearchBox from './SearchBox'


export class Header extends Component {

    handleLogout = () => {
        this.props.logout()
    }

    render() {
        const {userInfo} = this.props.userLogin
        return (
            <header>
               <Navbar bg="dark" variant="dark" collapseOnSelect>
                   <Container>
                       <LinkContainer to="/">
                       <Navbar.Brand>Oued-Kniss</Navbar.Brand>
                       </LinkContainer>
                        
                        <Nav className="mr-auto">
                            <LinkContainer to="/" exact>
                                <Nav.Link><i className="fas fa-home"></i>Home</Nav.Link>
                            </LinkContainer>

                            <LinkContainer to="/cart">
                            <Nav.Link><i className="fas fa-shopping-cart"></i>Cart</Nav.Link>
                            </LinkContainer>

                            {userInfo ? (
                                <NavDropdown title={userInfo.name} id='username'>
                                    <LinkContainer to='/profile'>
                                        <NavDropdown.Item>Profile</NavDropdown.Item>
                                    </LinkContainer>
                                    <NavDropdown.Item onClick={this.handleLogout}>Logout</NavDropdown.Item>
                                </NavDropdown>
                            )
                            :(
                                <LinkContainer to="/login">
                                    <Nav.Link><i className="fas fa-user"></i>Login</Nav.Link>
                                </LinkContainer>
                            )}

                            {userInfo && userInfo.isAdmin && (
                                <NavDropdown title="Admin" id='admin'>
                                <LinkContainer to='/admin/users'>
                                    <NavDropdown.Item>Users</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/admin/products'>
                                    <NavDropdown.Item>Products</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/admin/orders'>
                                    <NavDropdown.Item>Orders</NavDropdown.Item>
                                </LinkContainer>
                                </NavDropdown>
                            )
                            }
                            
                        </Nav>
                        <SearchBox />
                    </Container>
                </Navbar>
            </header>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        userLogin : state.userLogin,
    })
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        logout: logout,
    }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(Header)