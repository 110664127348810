import { 
            USER_LOGIN_REQUEST, USER_REGISTER_REQUEST,
            USER_LOGIN_SUCCESS, USER_REGISTER_SUCCESS,
            USER_LOGIN_FAIL,    USER_REGISTER_FAIL,
            USER_LOGOUT,

            USER_UPDATE_REQUEST,
            USER_UPDATE_SUCCESS,
            USER_UPDATE_FAIL,

            USER_DETAILS_REQUEST,
            USER_DETAILS_SUCCESS,
            USER_DETAILS_FAIL,

            USER_LIST_REQUEST,
            USER_LIST_SUCCESS,
            USER_LIST_FAIL,
            USER_LIST_RESET,

            USER_DELETE_REQUEST,
            USER_DELETE_SUCCESS,
            USER_DELETE_FAIL,

            USER_ADMIN_UPDATE_REQUEST,
            USER_ADMIN_UPDATE_SUCCESS,
            USER_ADMIN_UPDATE_FAIL,
        } from '../constants/userConstants'
import { ORDER_CUSTOMER_RESET, ORDER_DETAILS_RESET } from '../constants/OrderConstants'
import axiosInstance from '../axiosApi'


export const login = (email, password) => async (dispatch) => {
    try {
        dispatch({type: USER_LOGIN_REQUEST})
        const {data} = await axiosInstance.post('token/',
        {'username': email, password})

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })
        localStorage.setItem('userInfo', JSON.stringify(data))
        axiosInstance.defaults.headers['Authorization'] = "JWT " + data.access

    } catch (error) {
        dispatch({
            type : USER_LOGIN_FAIL,
            payload : error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
        })
    }
}

export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo')
    dispatch({type:USER_LOGOUT})
    dispatch({type:ORDER_CUSTOMER_RESET})
    dispatch({type:ORDER_DETAILS_RESET})
    dispatch({type:USER_LIST_RESET})
    axiosInstance.defaults.headers['Authorization'] = null
}

export const register = (name ,email, password) => async (dispatch) => {
    try {
        dispatch({type: USER_REGISTER_REQUEST})
        const {data} = await axiosInstance.post('user/register/',
        {name, email, password})

        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data
        })

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })
        axiosInstance.defaults.headers['Authorization'] = "JWT " + data.access
        localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type : USER_REGISTER_FAIL,
            payload : error.response && error.response.data
            ? error.response.data[0]
            : error.message,
        })
    }
}

export const updateUserProfile = (name ,email,oldPassword, password) => async (dispatch) => {
    try {
        dispatch({type: USER_UPDATE_REQUEST})
        const {data} = await axiosInstance.put('user/update/',
        {name, email, oldPassword, password})

        dispatch({
            type: USER_UPDATE_SUCCESS,
            payload: data
        })

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        axiosInstance.defaults.headers['Authorization'] = "JWT " + data.access
        localStorage.setItem('userInfo', JSON.stringify(data))


    } catch (error) {
        dispatch({
            type : USER_UPDATE_FAIL,
            payload : error.response && error.response.data
            ? error.response.data
            : error.message,
        })
    }
}


export const getListUsers = () => async (dispatch) => {
    try {
        dispatch({type: USER_LIST_REQUEST})
        const {data} = await axiosInstance.get('users')

        dispatch({
            type: USER_LIST_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type : USER_LIST_FAIL,
            payload : error.response && error.response.data
            ? error.response.data.detail
            : error.message,
        })
    }
}

export const deleteUser = (id) => async (dispatch) => {
    try {
        dispatch({type: USER_DELETE_REQUEST})
        const {data} = await axiosInstance.delete(`user/${id}/delete`)

        dispatch({
            type: USER_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type : USER_DELETE_FAIL,
            payload : error.response && error.response.data
            ? error.response.data.detail
            : error.message,
        })
    }
}

export const getUserDetails = (id) => async (dispatch) => {
    try {
        dispatch({type: USER_DETAILS_REQUEST})
        const {data} = await axiosInstance.get(`user/${id}`)

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type : USER_DETAILS_FAIL,
            payload : error.response && error.response.data
            ? error.response.data.detail
            : error.message,
        })
    }
}

export const updateUserByAdmin = (user) => async (dispatch) => {
    try {
        dispatch({type: USER_ADMIN_UPDATE_REQUEST})
        const {data} = await axiosInstance.put(`user/${user._id}/`, user)

        dispatch({
            type: USER_ADMIN_UPDATE_SUCCESS,
        })

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type :  USER_ADMIN_UPDATE_FAIL,
            payload : error.response && error.response.data
            ? error.response.data.detail
            : error.message,
        })
    }
}
