import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col, Button, ListGroup, Image, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { createOrder } from '../actions/orderActions'
import CheckOutSteps from '../components/CheckOutSteps'
import { MessageError } from '../components/MessageError'

export class PlaceOrderscreen extends Component {

    placeOrder = (itemsPrice, shippingPrice, taxPrice, totalPrice) => {
        const {shippingAddress, paymentMethod, cartItems} = this.props.cart
        this.props.createOrder({
            orderItems: cartItems,
            shippingAddress: shippingAddress,
            paymentMethod: paymentMethod,
            itemsPrice,
            shippingPrice,
            taxPrice,
            totalPrice
        })
    }

    componentDidMount() {
        const {history} = this.props
        if(this.props.cart === ''){
            history.push('/payment')
        }
    }

    componentDidUpdate() {
        const {success, order} = this.props.orderCreate
        const {history} = this.props
        if(success){
            history.push(`/order/${order._id}`)
        }
    }

    render() {
        const {shippingAddress, paymentMethod, cartItems} = this.props.cart
        const itemsPrice = cartItems.reduce((acc, item) => acc + (item.price * item.qty), 0).toFixed(2)
        const shippingPrice = (itemsPrice > 100 ? 0 : 10).toFixed(2)
        const taxPrice = (0.073 * itemsPrice).toFixed(2)
        const totalPrice = (Number(itemsPrice) + Number(shippingPrice) + Number(taxPrice)).toFixed(2)
        const {error, loading} = this.props.orderCreate
        return (
            <div>
                <CheckOutSteps step1 step2 step3 step4/>
                <Row>
                    <Col md={8}>
                        <ListGroup variant='flush'>
                            <ListGroup.Item>
                                <h2>Shipping</h2>
                                <p>
                                    <strong>Shipping: </strong>
                                    {shippingAddress.address}, {shippingAddress.city},
                                        {`   ${shippingAddress.postalCode}`},
                                        {`   ${shippingAddress.country}`}
                                </p>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <h2>Payment Method</h2>
                                <p>
                                    <strong>Method: </strong>
                                    {paymentMethod}
                                </p>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <h2>Order Items</h2>
                                {cartItems.lenght === 0 ?
                                <MessageError variant='info'>Your cart is empty</MessageError>
                                : (
                                    <ListGroup variant='flush'>
                                        {cartItems.map((item, index) => (
                                            <ListGroup.Item key={index}>
                                                <Row>
                                                    <Col md={1}>
                                                        <Image src={item.image} alt={item.name} fluid rounded/>
                                                    </Col>
                                                    <Col>
                                                        <Link to={`product/${item.product}`}>{item.name}</Link>
                                                    </Col>
                                                    <Col md={4}>
                                                        {item.qty} x ${item.price} = ${(item.qty*item.price).toFixed(2)}
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                )}
                                
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <ListGroup variant='flush'>
                                <ListGroup.Item>
                                    <h2>Order Summary</h2>
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <Row>
                                        <Col>
                                            Items: 
                                        </Col>

                                        <Col>
                                            ${itemsPrice} 
                                        </Col>
                                    </Row>                
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <Row>
                                        <Col>
                                            Shipping: 
                                        </Col>

                                        <Col>
                                            ${shippingPrice}
                                        </Col>
                                    </Row>                
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <Row>
                                        <Col>
                                            Tax: 
                                        </Col>

                                        <Col>
                                            ${taxPrice}
                                        </Col>
                                    </Row>                
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <Row>
                                        <Col>
                                            Total: 
                                        </Col>

                                        <Col>
                                            ${totalPrice}
                                        </Col>
                                    </Row>                
                                </ListGroup.Item>
                                
                                {error&&
                                <ListGroup.Item>{error}</ListGroup.Item>
                                }

                                <ListGroup.Item>
                                   <Button 
                                   type='button'
                                    block
                                    disabled={cartItems.length ===0}
                                    onClick={(e) => this.placeOrder(itemsPrice, shippingPrice, taxPrice, totalPrice)}
                                    >{loading? 'loading ....':'Place Order'}</Button>     
                                </ListGroup.Item>

                            </ListGroup>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        createOrder: createOrder,
    }, dispatch)
}

const mapStateToProps = (state) => {
    return ({
        cart: state.cart,
        orderCreate: state.orderCreate,
    })
}

export default connect(mapStateToProps, matchDispatchToProps)(PlaceOrderscreen)