import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Image, Carousel } from 'react-bootstrap'
import Loader from './Loader'
import MessageError from './MessageError'
import { listTopProducs } from '../actions/productActions'
import { Link } from 'react-router-dom'

function ProductCarossel() {

    const dispatch = useDispatch()

    const productTopRated = useSelector(state => state.productTopRated)
    const {loading, error, products} = productTopRated

    useEffect(() => {
        dispatch(listTopProducs())
    }, [dispatch])

    return ( loading ? <Loader />
    :error ? <MessageError variant='dager'>{error}</MessageError>
    :(
        <Carousel pause='hover' className="bg-dark">
            {products.map((product) => (
                <Carousel.Item key={product._id}>
                    <Link to={`/product/${product._id}`}>
                        <Image src={product.image} alt={product.name} fluid/>
                        <Carousel.Caption className='carousel.caption'>
                            <h4>{product.name} (${product.price})</h4>
                        </Carousel.Caption>
                    </Link>
                </Carousel.Item>
            ))}
        </Carousel>
    )
        
    )
}

export default ProductCarossel
