import axios from 'axios'

const baseURL = 'http://127.0.0.1:8000/api/'

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 5000,
    headers: {
        'Authorization': localStorage.getItem('userInfo') ? "JWT " + JSON.parse(localStorage.getItem('userInfo')).access : null,
    }
});

export default axiosInstance