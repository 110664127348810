import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { deliverOrder, getOrderDetails, payOrder } from '../actions/orderActions'
import { Row, Col, Button, ListGroup, Image, Card, Form, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { MessageError } from '../components/MessageError'
import Loader from '../components/Loader'
import axios from 'axios'


export class Orderscreen extends Component {

    constructor(props){
        super(props)
        this.state = {
            username: '',
            password: '',
            errMsg: '',
        }
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleSubmit = (e, totalPrice) => {
        e.preventDefault()
        this.setState({errMsg: ''})
        const {id} = this.props.match.params
        const {username, password} = this.state
        const form = {
            username,
            password,
            To: "BANK",
            amount: Number(totalPrice)
        }
        axios.post("http://127.0.0.1:5000/transfer", form)
        .then(resp => console.log(resp.data))
        .then(() => this.props.payOrder(id))
        .catch(err => this.setState({errMsg: err.response.data.message}))
    }

    handleDeliver = () => {
        const {id} = this.props.match.params
        this.props.deliverOrder(id)
    }

    componentDidMount() {
        const {userInfo} = this.props.userLogin
        const {history} = this.props
        const {id} = this.props.match.params
        if(!userInfo){
            history.push('/login')
        }
        this.props.getOrderDetails(id)
    }

    componentDidUpdate() {
        const {history} = this.props
        const {userInfo} = this.props.userLogin
        if(!userInfo){
            history.push('/login')
        }
    }

    render() {
        const {loading, error, order} = this.props.orderDetails
        const payLoading = this.props.orderPay.loading
        const deliverLoading = this.props.orderDeliver.loading
        const {userInfo} = this.props.userLogin
        const {errMsg} = this.state
        let itemsPrice
        if(order){
            itemsPrice = order.orderItems
            .reduce((acc, item) => acc + (item.price * item.qty), 0).toFixed(2)
        }
        return (
            <div>
                {loading? <Loader/>
                :
                error ? <MessageError variant='danger'>{error}</MessageError>
                :    
                (   
                    <Row>
                        <Col md={8}>
                            <ListGroup variant='flush'>
                                <ListGroup.Item>
                                    <h2>Shipping</h2>
                                    <p><strong>Name: </strong> {order.user.name}</p>
                                    <p><strong>Email: </strong> <a href={`mailto:${order.user.email}`}>{order.user.email}</a></p>
                                    <p>
                                        <strong>Shipping: </strong>
                                        {order.shippingAddress.address}, {order.shippingAddress.city},
                                            {`   ${order.shippingAddress.postalCode}`},
                                            {`   ${order.shippingAddress.country}`}
                                    </p>
                                    {order.isDelivered ?
                                    <MessageError variant='success'>Delivered on {new Date(`${order.deliveredAt}`).toDateString()}</MessageError>
                                    :
                                    <MessageError variant='warning'>Not delivered</MessageError>
                                    }
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <h2>Payment Method</h2>
                                    <p>
                                        <strong>Method: </strong>
                                        {order.paymentMethod}
                                    </p>
                                    {order.isPaid ?
                                    <MessageError variant='success'>Paid on: {new Date(`${order.paidAt}`).toDateString()}</MessageError>
                                    :
                                    <MessageError variant='warning'>Not paid</MessageError>
                                    }
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <h2>Order Items</h2>
                                    {order.orderItems.lenght === 0 ?
                                    <MessageError variant='info'>Your cart is empty</MessageError>
                                    : (
                                        <ListGroup variant='flush'>
                                            {order.orderItems.map((item, index) => (
                                                <ListGroup.Item key={index}>
                                                    <Row>
                                                        <Col md={1}>
                                                            <Image src={item.image} alt={item.name} fluid rounded/>
                                                        </Col>
                                                        <Col>
                                                            <Link to={`product/${item.product}`}>{item.name}</Link>
                                                        </Col>
                                                        <Col md={4}>
                                                            {item.qty} x ${item.price} = ${(item.qty*item.price).toFixed(2)}
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    )}
                                    
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <ListGroup variant='flush'>
                                    <ListGroup.Item>
                                        <h2>Order Summary</h2>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                Items: 
                                            </Col>

                                            <Col>
                                                ${itemsPrice} 
                                            </Col>
                                        </Row>                
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                Shipping: 
                                            </Col>

                                            <Col>
                                                ${order.shippingPrice}
                                            </Col>
                                        </Row>                
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                Tax: 
                                            </Col>

                                            <Col>
                                                ${order.taxPrice}
                                            </Col>
                                        </Row>                
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                Total: 
                                            </Col>

                                            <Col>
                                                ${order.totalPrice}
                                            </Col>
                                        </Row>                
                                    </ListGroup.Item>
                                    
                                    {error&&
                                    <ListGroup.Item><MessageError variant='dark'>{error}</MessageError></ListGroup.Item>
                                    }
                                    {errMsg&&
                                    <ListGroup.Item><MessageError variant='dark'>{errMsg}</MessageError></ListGroup.Item>
                                    }
                                    {!order.isPaid &&
                                        <ListGroup.Item>
                                        <Form onSubmit={(e) => this.handleSubmit(e, order.totalPrice)}>
                                            <h3 className="text-center"><strong>Card info</strong></h3>
                                            <Form.Group controlId="formBasicName">
                                                <Form.Label>Username</Form.Label>
                                                <Form.Control type="name" placeholder="Enter username" required
                                                value={this.state.username} onChange={this.handleChange} name="username"/>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control type="password" placeholder="Enter password" required
                                                value={this.state.password} onChange={this.handleChange} name="password"/>
                                            </Form.Group>
                                            <Button type='submit'
                                                variant='outline-success'
                                                block
                                                disabled={order.orderItems.length ===0 || payLoading}
                                                >{payLoading? <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />:<i className="far fa-credit-card"></i>}</Button> 
                                        </Form>
                                        
                                    </ListGroup.Item>
                                    }
                                    {userInfo && userInfo.isAdmin && order.isPaid && !order.isDelivered &&
                                        <ListGroup.Item>
                                            <Button block
                                             variant="outline-success"
                                             onClick={this.handleDeliver}>
                                                {deliverLoading? <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                : 'Mark as deliver'}
                                            </Button>
                                        </ListGroup.Item>
                                    }   
                                    
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>
                )
                }
                
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return ({
        orderDetails : state.orderDetails,
        userLogin: state.userLogin,
        orderPay: state.orderPay,
        orderDeliver: state.orderDeliver,
    })
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getOrderDetails: getOrderDetails,
        payOrder: payOrder,
        deliverOrder: deliverOrder,
    }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(Orderscreen)
