import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col, ListGroup, Image, Form, Button } from 'react-bootstrap'
import { MessageError } from '../components/MessageError'
import { addToCart, removeFromCart } from '../actions/cartActions'
import { Link } from 'react-router-dom'

export class Cartscreen extends Component {

    handleChange = (id, qty) => {
        this.props.addToCart(id, qty)
    }

    removeItemFromCart = (id) => {
        console.log("item removed ", id)
        this.props.removeFromCart(id)
    }

    checkoutHandler = () => {
        this.props.history.push(`/login?redirect=shipping`)
    }

    componentDidMount() {
        const {match, location} = this.props
        const productId = match.params.id
        const qty = location.search ? parseInt(location.search.split('=')[1]) : 1
        if(productId){
            this.props.addToCart(productId, qty)
        }
    }
    render() {
        const {cartItems} = this.props.cart
        return (
            <Row>
                <Col md={8}>
                    <h1 className="my-3">Shopping cart</h1>
                    {cartItems.length === 0 ?
                     <div>
                         <MessageError variant='success'>
                            Your Cart is empty <Link to="/">Go Back</Link>
                         </MessageError>
                    </div> 
                    : 
                        <ListGroup variant="flush">
                            {cartItems.map(item => (
                                <ListGroup.Item key={item.product}>
                                    <Row>
                                        <Col md={2}>
                                            <Image src={item.image} alt={item.name} fluid rounded/>
                                        </Col>

                                        <Col md={3}>
                                            <Link to={`/product/${item.product}`}>{item.name}</Link>
                                        </Col>

                                        <Col md={2}>
                                            ${item.price}
                                        </Col>

                                        <Col md={3}>
                                            <Form.Control as="select"
                                            value={item.qty}
                                            onChange={(e) => this.handleChange(item.product, parseInt(e.target.value))}>
                                                {
                                                [...Array(item.countInStock).keys()].map((val) => (
                                                    <option key={val + 1} value={val + 1}>
                                                        {val + 1}
                                                    </option>
                                                ))
                                                }
                                            </Form.Control>
                                        </Col>
                                        <Col md={1}>
                                        <Button className="btn btn-success"
                                        type="button" onClick={(e) => this.removeItemFromCart(item.product)}>
                                            <i className='fas fa-trash'></i>
                                        </Button>
                                        </Col>

                                    </Row>
                                </ListGroup.Item>
        ))}
                        </ListGroup>
                    }
                </Col>  
                <Col md={4}>
                    <ListGroup variant='flush'>
                        <ListGroup.Item>
                        <Row>
                            <Col><h2>Subtotal:</h2></Col>
                            <Col><h2>{cartItems.reduce((acc, item) => acc + item.qty, 0)} items</h2></Col>
                        </Row>

                        </ListGroup.Item>

                        <ListGroup.Item>
                        <Row>
                            <Col><h2>Total:</h2></Col>
                            <Col><h2><strong>${cartItems.reduce((acc, item) => acc + (item.qty*item.price), 0).toFixed(2)}</strong></h2></Col>
                        </Row>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <Button className="btn btn-block" disabled={cartItems.length === 0}
                             type="button" onClick={this.checkoutHandler}>Proceed to Checkout</Button>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>  
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        cart : state.cart,
    })
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        addToCart: addToCart,
        removeFromCart: removeFromCart,
    }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(Cartscreen)