import React, { Component } from 'react'
import { Spinner } from 'react-bootstrap'

export class Loader extends Component {
    render() {
        return (
            <Spinner animation="border"
              variant="primary"
              role="status"
              style={{
                  height: '100px',
                  width: '100px',
                  margin: 'auto',
                  display: 'block'
              }}   
              >
                  <span className="sr-only">Loading...</span>
            </Spinner>
        )
    }
}

export default Loader
