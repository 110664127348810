import React, {useEffect} from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import { MessageError } from '../components/MessageError'
import { getListOrders } from '../actions/orderActions'

function OrderListscreen({history}) {
    const dispatch = useDispatch()

    const orderList = useSelector(state => state.orderList)
    const {loading, error, orders} = orderList

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin


    useEffect(() => {
        if(userInfo && userInfo.isAdmin){
            dispatch(getListOrders())
        }else{
            history.push('/login')
        }
        
    }, [dispatch, history, userInfo])

    
    return (
        <div>
            <h1>Orders</h1>
            {loading?
            <Loader/>
            :
            error ? <MessageError variant='danger'>{error}</MessageError>
            :
            <Table striped bordered hover responsive className="table-sm">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>USER</th>
                        <th>DATE</th>
                        <th>TOTAL</th>
                        <th>PAID</th>
                        <th>DELEIVERED</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {orders.map(order => (
                        <tr key={order._id}>
                            <td>{order._id}</td>
                            <td>{order.user.name}</td>
                            <td>{new Date(order.createdAt).toDateString()}</td>
                            <td>${order.totalPrice}</td>
                            <td>{order.isPaid ?
                            new Date(order.paidAt).toDateString()
                            :
                            <i className="fas fa-times" style={{color: "red"}}></i>
                            }</td>

                            <td>{order.isDelivered ?
                            new Date(order.deliveredAt).toDateString()
                            :
                            <i className="fas fa-times" style={{color: "red"}}></i>
                            }</td>
                            
                            <td>
                                <LinkContainer to={`/order/${order._id}`}>
                                    <Button size="sm" variant="dark">
                                    <i className="fas fa-edit"></i>
                                    </Button>
                                </LinkContainer>
                                
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            }
        </div>
    )
}

export default OrderListscreen
