import React, { Component } from 'react'
import { Alert } from 'react-bootstrap'

export class MessageError extends Component {
    render() {
        const {children} = this.props
        const {variant} = this.props
        return (
            <Alert variant={variant}>
                {children}
            </Alert>
        )
    }
}

export default MessageError
